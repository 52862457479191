var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c('vue-element-loading',{attrs:{"active":_vm.submiting}}),_c('v-card',{staticClass:"auth-card"},[_c('v-card-title',{staticClass:"d-flex align-center justify-center py-7"},[_c('v-img',{staticClass:"me-3",attrs:{"src":require('@/assets/images/logos/logo.png'),"max-height":"30px","max-width":"30px","alt":"logo","contain":""}}),_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.systemName)+" ")])],1),_c('v-card-text',[_c('p',{staticClass:"font-weight-semibold text--primary mb-2"},[_vm._v(" Create a new school account ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Please fill the form to create a new school account ")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","label":"Full School Name","placeholder":"JohnDoe","required":"","rules":_vm.userNameRules,"hide-details":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","label":"Email","placeholder":"john@example.com","hide-details":"","required":"","rules":_vm.emailRules},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"outlined":"","type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Password","required":"","rules":_vm.passRules,"placeholder":"············","append-icon":_vm.isPasswordVisible
                                ? _vm.icons.mdiEyeOffOutline
                                : _vm.icons.mdiEyeOutline,"hide-details":""},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree to "),_c('v-tooltip',{attrs:{"location":"bottom"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var props = ref.props;
return [_c('a',_vm._b({attrs:{"href":"/terms","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},'a',props,false),[_vm._v(" Terms & Conditions ")])]}}])})],1)]},proxy:true}]),model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}}),_c('v-card-text',{staticClass:"d-flex align-center justify-center flex-wrap mt-2"},[_c('span',{staticClass:"me-2"},[_vm._v(" Need help? ")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.adminInfoDialog = true}}},[_vm._v(" Contact Us ")])]),_c('VueSimpleRecaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.siteKey,"invisible":true},on:{"callback":_vm.callback}}),_c('v-btn',{staticClass:"mt-6",attrs:{"block":"","color":"primary","disabled":!_vm.agree},on:{"click":_vm.register}},[_vm._v(" Sign Up ")])],1)],1),_c('v-card-text',{staticClass:"d-flex align-center justify-center flex-wrap mt-2"},[_c('span',{staticClass:"me-2"},[_vm._v(" Already have an account? ")]),_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v(" Sign in instead ")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.adminInfoDialog),callback:function ($$v) {_vm.adminInfoDialog=$$v},expression:"adminInfoDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Contact Us")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_vm._v(" Email: "),_c('a',_vm._b({},'a',{
                                            href:
                                                'mailto:' + _vm.adminInfo.email,
                                        },false),[_vm._v(" "+_vm._s(_vm.adminInfo.email)+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_vm._v(" Phone: "),_c('a',_vm._b({},'a',{
                                            href: 'tel:' + _vm.adminInfo.phone,
                                        },false),[_vm._v(" "+_vm._s(_vm.adminInfo.phone)+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Address: "),_c('span',[_vm._v(_vm._s(_vm.adminInfo.address))])])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.adminInfoDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"190","src":require(("@/assets/images/misc/mask-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}}),_c('v-img',{staticClass:"auth-tree my-5",attrs:{"width":"247","height":"185","src":require("@/assets/images/misc/school.png")}}),_c('v-img',{staticClass:"auth-tree-3",attrs:{"width":"400","height":"250","src":require("@/assets/images/misc/school-bus-side-view.png")}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }